// Cards Mixins 

@mixin card-inverse-variant($bg, $border, $color) {
  background: $bg;
  border: 1px solid $border;
  color: $color;
}


.card-body{
  border: 1px solid black;
  color: black;
}

.text-light {
   color: black !important; 
}