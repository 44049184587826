/* Container for the form */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    background-color: #f0f2f5;
  }
  
  /* Main form container */
  .formContainer {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 500px;
  }
  
  /* Heading */
  .heading {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Subheading */
  .subHeading {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    color: #666;
  }
  
  /* Form styles */
  .form {
    display: flex;
    flex-direction: column;
  }
  
  /* Input group styles */
  .inputGroup {
    margin-bottom: 20px;
  }
  
  /* Common input styles */
  .input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
  }
  
  .input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* File upload container */
  .fileUpload {
    position: relative;
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  /* File input element hidden */
  .fileUpload input[type="file"] {
    display: none;
  }
  
  /* Upload prompt styles */
  .uploadPrompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #007bff;
  }
  
  /* Upload icon */
  .uploadIcon {
    font-size: 30px;
    margin-bottom: 10px;
  }
  
  /* Upload text */
  .uploadText {
    font-size: 16px;
    font-weight: 500;
  }
  
  /* Uploaded file container */
  .uploaded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
  }
  
  /* File icon */
  .fileIcon {
    width: 26px;
    margin-right: 10px;
  }
  
  /* File details */
  .fileDetails {
    flex-grow: 1;
  }
  
  /* File name */
  .fileName {
    font-size: 16px;
    color: #333;
  }
  
  /* File size */
  .fileSize {
    font-size: 14px;
    color: #666;
  }
  
  /* Remove icon */
  .removeIcon {
    width: 20px;
    cursor: pointer;
  }
  
  /* Submit button */
  .submitButton {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  /* Loader styles */
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loaderImage {
    width: 100px;
  }
  