
#dashFooter{
    background-color: unset !important;
}
.atropos-rotate-touch{
    touch-action: unset!important;
}
.atropos-shadow{
    display: none!important;
}
@media screen and (max-width: 575px) {
    .content-wrapper h3{
        font-size:18px;
    }
}